import React from 'react';
import './Footer.css';
export const Footer = () => {
	return (
		<footer className="footer wrapper" id="footer">
			<div className="footer-list">
				<h1 className="logo">ООО «ВитАльфаСервис»</h1>

				<div className="footer-list-container" id="Contacts">
					<p className="footer-text">Прием звонков: с 10:00 до 22:00 (Пн-Пт)</p>
					<div className="tel-list">
						<a className="tel-item" href="tel:+375297037457">
							<span>☎</span>
							+375 (29) 703-74-57 (Директор)
						</a>
						<span>|</span>
						<a className="tel-item" href="tel:+375296207393">
							<span>☎</span>
							+375 (29) 620-73-93 (Диспетчерская)
						</a>
						<span>|</span>
						<a className="tel-item" href="tel:+375298529410">
							<span>☎</span>
							+375 (29) 852-94-10 (Консультация)
						</a>
						<span>|</span>
						<a className="tel-item" href="mailto:vitalfaservis@mail.ru">
							<span>✉</span>
							Написать<br/> нам
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

