import React, {useEffect} from 'react';
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
import {Header} from "./header/Header";
import {Main} from "./main/Main";
import {Footer} from "./footer/Footer";
import {useLocationHash} from "./hooks/useLocation";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 300,
    });
  }, []);

  const {hash, setHash} = useLocationHash();

  return (
    <div className="App">
      <Header setHash={setHash}/>
      <Main hash={hash}/>
      <Footer />
    </div>
  );
}

export default App;
