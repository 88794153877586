import React, { useLayoutEffect, useState} from 'react'
import './Header.css';
import {useWindowWidth} from "@react-hook/window-size";
import {Burger} from "./burger/Burger";

interface Props {
	setHash: React.Dispatch<React.SetStateAction<string>>;
}

export const Header: React.FC<Props> = ({  setHash }) => {
	const width = useWindowWidth();
	const [isMobile, setIsMobile] = useState<boolean>();


	useLayoutEffect(() => {
		if(width < 1024) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	},[width]);

	const handleSmoothClick = (e: React.MouseEvent<HTMLAnchorElement>, querySelector: string) => {
		let section = document.querySelector(querySelector);
		e.preventDefault();  // Stop Page Reloading
		if(!!section)  {
			setHash(querySelector);
			window.history.pushState(null, '', querySelector);  // Add hash to the URL
			section.scrollIntoView({
				behavior: 'smooth',
			});
		}

	}

	return (
		<>
			{isMobile
				?
				<Burger />
				:
				<div className="wrapper header">
					<div className="header-list">
						<h1 className="logo">ООО «ВитАльфаСервис»</h1>

						<div className="link-list-container">
							<div className="link-list">
								<a
									href="#"
									onClick={e => handleSmoothClick(e, '#Services')}
								>
									Услуги
								</a>
								<a
									href="#"
									onClick={e => handleSmoothClick(e, '#Contacts')}
								>
									Контакты
								</a>
								<a
									href="#"
									onClick={e => handleSmoothClick(e, '#Feedback')}
								>
									Отзывы
								</a>
							</div>
							<div className="tel-list">
								<a className="tel-item" href="tel:+375296207393">
									<span>☎</span>
									+375 (29) 620-73-93 (Диспетчерская)
								</a>
								<span>|</span>
								<a className="tel-item" href="tel:+375298529410">
									<span>☎</span>
									+375 (29) 852-94-10 (Консультация)
								</a>
								<span>|</span>
								<a href="mailto:vitalfaservis@mail.ru">
									<span>✉</span>
									Написать<br/> нам
								</a>
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

