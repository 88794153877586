import React from 'react';
import {WelcomeSection} from "./welcome/Welcome.section";
import {MainSection} from "./mainSection/MainSection";
import {FeedbackSection} from "./feedback/Feedback.section";

interface Props {
	hash: string;
}

export const Main:React.FC<Props> = ({  hash }) => {
	return (
		<main>
			<WelcomeSection />
			<MainSection hash={hash}/>
			<FeedbackSection />
		</main>
	);
};

