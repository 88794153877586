import React, {useEffect, useState} from 'react';
import './MainSection.css';

interface Props {
	hash: string;
}

export const MainSection:React.FC<Props> = ({  hash }) => {
	const [isShowServices, setIsShowServices] = useState(false);

		useEffect(() => {
			if(hash === '#Services') {
				setIsShowServices(true);
			} else {
				setIsShowServices(false);
			}
		}, [hash]);


	return (
			<div className="main-content wrapper">
				<h2 className="section-title">О нас</h2>
				<div className="row row-reverse">
					<p className="mainSection-text" data-aos="fade-left">
						ООО «ВитАльфаСервис» предлагает комплекс услуг
						по техническому обслуживанию лифтового оборудования в г. Минске.
						Наша команда – это высококвалифицированные специалисты,
						которые быстро реагируют на любые проблемы и эффективно их устраняют,
						чтобы обеспечить бесперебойную работу вашего оборудования.
					</p>
					<div className="main-section-image-wrapper" data-aos="fade-up">
						<img className="worker-img" src={"images/worker3.webp"} alt="Рабочий в шахте лифта"/>
					</div>
				</div>

				<div className="row">
					<div className="support-devices-wrapper" data-aos="fade-right">
						<p className="mainSection-text">
							Имеет опыт проведения работ на лифтовом оборудовании таких производителей как:
						</p>

						<ul className="support-devices-list">
							<li>Щербинский Лифтостроительный Завод</li>
							<li>Карачаровский Механический Завод</li>
							<li>Гомельский лифтовой Завод</li>
							<li>Могилёвлифтмаш</li>
							<li>OTIS</li>
							<li>HAS</li>
							<li>ORONA</li>
						</ul>
					</div>

					<div className="main-section-image-wrapper" data-aos="fade-up">
						<img className="worker-img" src={"images/worker2.webp"} alt="Рабочий в шахте лифта"/>
					</div>
				</div>

				<div className="row row-reverse">
					<div className="support-devices-wrapper" data-aos="fade-left">
						<p className="mainSection-text">
							Лифты выполняют очень важную функцию – транспортировку пассажиров.
							Поэтому очень важно, чтобы они были надежными, безопасными и комфортными.
						</p>
						<p className="mainSection-text">
							Ряд мероприятий, которые проводят с целью предупреждения неполадок в работе,
							а также устранения помех, выявленных во время его эксплуатации
							называется техническим обслуживанием.
							Своевременные меры профилактики неполадок необходимы,
							так как они помогают избегать длительных, дорогостоящих ремонтов.
						</p>
					</div>

					<div className="main-section-image-wrapper" data-aos="fade-up">
						<img className="worker-img" src={"images/worker4.webp"} alt="Рабочий в шахте лифта"/>
					</div>
				</div>

				<div className="row">
					<div className="support-devices-wrapper" data-aos="fade-right">
						<p

							className={`mainSection-text ${isShowServices && 'services_show'}`}
							id="Services"
						>
							Специалисты ООО «ВитАльфаСервис» осуществляют весь комплекс работ по
							<i> техническому обслуживанию лифтов</i>,
							их <i>подготовке к техническому освидетельствованию </i>
							и <i>диагностированию</i>  для содержания оборудования в исправном состоянии,
							в том числе проведение <i>периодических технических осмотров</i>,
							<i> ежемесячных и ежегодных регламентных работ</i>.
						</p>
						<p className="mainSection-text">
							Работающий персонал осуществляет контроль над работой техники 24 часа в сутки.
							Диспетчеры работают непрерывно, что делает использование лифтового оборудования максимально безопасным и удобным.
						</p>
					</div>

					<div className="main-section-image-wrapper" data-aos="fade-up">
						<img className="worker-img" src={"images/worker1.webp"} alt="Рабочий в шахте лифта"/>
					</div>
				</div>
			</div>
	);
};

