import React, {useLayoutEffect, useState} from 'react';
import './Welome.css';
import { Parallax } from 'react-parallax';
import {useWindowWidth} from "@react-hook/window-size";

export const WelcomeSection = () => {
	const width = useWindowWidth();
	const [imageWidth, setImageWidth] = useState('100%');

	useLayoutEffect(() => {
		if(width < 480) {
			setImageWidth('150%');
		} else {
			setImageWidth('100%');
		}
	},[width])

	return (
			<Parallax
				className="parallax"
				bgImage="/images/bg-2.jpg"
				bgImageAlt="Шахта Лифта"
				bgImageStyle={{width: `${imageWidth}`, height: 'auto'}}
				strength={500}
			>
				<div id="welcome" className="wrapper section-content">
					<div className="welcome-text-block">
						<p className="welcome-title">
							<b data-aos="fade-left">Оперативность</b>
							<br/>
							<span data-aos="fade-left" data-aos-delay="500">Надёжность</span>
							<br/>
							<span data-aos="fade-left" data-aos-delay="1000">Качество</span>
						</p>

						<p className="welcome-subtitle">
							Комплексный подход по техническому
							<br/>
							обслуживанию лифтов
						</p>
					</div>
				</div>
			</Parallax>
	);
};
