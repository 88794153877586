import React, {useEffect, useState} from 'react';
import {CloseIconSvg} from "../../ui/close.icon.svg";
import {BurgerOpenSvg} from "../../ui/burger-open.svg";

export const Burger = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isAnimating, setIsAnimating] = useState(false);

	useEffect(() => {
		if (isAnimating && !isOpen) {
			const timer = setTimeout(() => {
				setIsAnimating(false);
			}, 1400);
			return () => clearTimeout(timer);
		}
	}, [isOpen, isAnimating]);

	const handleClose = () => {
		if(!isOpen) {
			setIsAnimating(true);
		}
		setIsOpen(!isOpen);
	};

	const burgerStyle = () => {
		if(isAnimating && isOpen) {
			return {display: 'block'}
		} else if(!isAnimating && !isOpen) {
			return {display: 'none'}
		} else {
			return ;
		}
	}

	return (
		<div className="wrapper close-burger-wrapper">
			<div className="burger-wrapper">
				<h1 className="logo">ООО «ВитАльфаСервис»</h1>

				<div onClick={handleClose}>
					{!isOpen ? <BurgerOpenSvg /> : <CloseIconSvg />}
				</div>

					<div className={`burger-content burger-bg ${isOpen ? 'open' : 'closed'}`} style={burgerStyle()}>
						<div className="wrapper">
							<div className="header-list">
								<div className="link-list-container">
									<div className="link-list">
										<a href="#Services">Услуги</a>
										<a href="#Contacts">Контакты</a>
										<a href="#Feedback" >Отзывы</a>
									</div>

									<div className="tel-list">
										<a className="tel-item" href="tel:+375296207393">
											<span>☎</span>
											+375 (29) 620-73-93 (Диспетчерская)
										</a>
										<span className="mobile-none">|</span>
										<a className="tel-item" href="tel:+375298529410">
											<span>☎</span>
											+375 (29) 852-94-10 (Консультация)
										</a>
										<span className="mobile-none">|</span>
										<a href="mailto:vitalfaservis@mail.ru">
											<span>✉</span>
											Написать<br className="mobile-none"/> нам
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	);
};


			{/*<div className="burger-size" style={isOpen && isAnimating ? {display: 'block'} : {display : 'none'}}>*/}
			{/*	<div className={`burger-content burger-bg ${isOpen ? 'open' : 'closed'}`} >*/}
			{/*		<div className="wrapper">*/}
			{/*			<div className="burger-wrapper">*/}
			{/*				<h1 className="logo">ООО «ВитАльфаСервис»</h1>*/}
			{/*				<div onClick={handleClose}>*/}
			{/*					<CloseIconSvg />*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="header-list">*/}
			{/*				<div className="link-list-container">*/}
			{/*					<div className="link-list">*/}
			{/*						<a href="#Services">Услуги</a>*/}
			{/*						<a href="#Contacts">Контакты</a>*/}
			{/*						<a href="#Feedback" >Отзывы</a>*/}
			{/*					</div>*/}
			{/*					<div className="tel-list">*/}
			{/*						<a className="tel-item" href="tel:+375296207393">*/}
			{/*							<span>☎</span>*/}
			{/*							+375 (29) 620-73-93 (Диспетчерская)*/}
			{/*						</a>*/}
			{/*						<span>|</span>*/}
			{/*						<a className="tel-item" href="tel:+375298529410">*/}
			{/*							<span>☎</span>*/}
			{/*							+375 (29) 852-94-10 (Консультация)*/}
			{/*						</a>*/}
			{/*						<span>|</span>*/}
			{/*						<a href="mailto:vitalfaservis@mail.ru">*/}
			{/*							<span>✉</span>*/}
			{/*							Написать<br/> нам*/}
			{/*						</a>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<div className="wrapper close-burger-wrapper" style={!isOpen && !isAnimating  ? {display: 'block'} : {display : 'none'}}>*/}
			{/*	<div className="burger-wrapper">*/}
			{/*		<h1 className="logo">ООО «ВитАльфаСервис»</h1>*/}
			{/*		<div onClick={handleClose}>*/}
			{/*			<BurgerOpenSvg />*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
