import React, {useLayoutEffect, useState} from 'react';
import './Feedback.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar} from 'swiper/modules';
import {useWindowWidth} from "@react-hook/window-size";
import {Parallax} from "react-parallax";

export const FeedbackSection = () => {
	const FEEDBACK_LIST = [1,2,3,4,5,6];
	const  width = useWindowWidth()
	const [slidesPerView, setSlidesPerView] = useState(1);
	const [parallaxWidth, setParallaxWidth] = useState('100%');
	const [strength, setStrength] = useState(300);
	const [isShowSwiperControls, setIsShowSwiperControls] = useState(false);

	useLayoutEffect(() => {
		if(width < 479) {
			setParallaxWidth('250%');
			setSlidesPerView(1);
			setStrength(160);
		}else if(width < 500 && width >= 480) {
			setParallaxWidth('200%');
			setSlidesPerView(1);
			setStrength(160);
		}else if(width < 600 && width >= 500) {
			setParallaxWidth('180%');
			setSlidesPerView(1);
			setStrength(160);
		} else if(width < 1024 && width >= 600) {
			setSlidesPerView(1);
			setStrength(160);
			setParallaxWidth('150%');
		} else if(width < 1440 && width >= 1024) {
			setSlidesPerView(3);
			setParallaxWidth('120%');
			setStrength(300);
		} else {
			setParallaxWidth('100%');
			setSlidesPerView(3);
			setStrength(300);
		}
	},[width]);

	return (
		<Parallax
			className="feedback-parallax"
			bgImage="/images/bg-4.webp"
			bgImageAlt="Шахта Лифта"
			bgImageStyle={{width: `${parallaxWidth}`, height: 'auto'}}
			strength={strength}
				>
					<div
						className="feedback-section"
						id="Feedback"
						onMouseEnter={() => setIsShowSwiperControls(true)}
						onMouseLeave={() => setIsShowSwiperControls(false)}
					>
						<div className="wrapper feedback-content">
							<h2 className="main-header">Отзывы</h2>

							<div className="swiper-container">
								<Swiper
									style={{
										"--swiper-theme-color": "#2369b8",
										"--swiper-pagination-bullet-size": "16px",
									}}
									modules={[Navigation, Pagination, Scrollbar]}
									spaceBetween={40}
									slidesPerView={ slidesPerView}
									navigation={isShowSwiperControls && slidesPerView !== 1}
									pagination={{ clickable: true }}
								>
									{FEEDBACK_LIST.map((item) => (
										<SwiperSlide key={item}>
											<div className="item-card">
												<a href="#"
												   onClick={async (e) => {
													   e.preventDefault();
													   const pdfModule = await import(`../../pdf/${item}.pdf`);
													   window.open(pdfModule.default);
												   }}
												   target="_blank"
												   rel="noreferrer">
													<img className="image-item" src={`/images/feedback/${item}.webp`} alt={"Рекомендательное письмо"}/>
												</a>
											</div>
										</SwiperSlide>
									))}
								</Swiper>
							</div>
						</div>
					</div>
		</Parallax>
	);
};

